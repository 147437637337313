import styles from "./styles.module.css";

function Paragraph({ text = "text", color, fontSize }) {
  return (
    <div
      className={styles.Paragraph}
      style={{ color: color, fontSize: fontSize }}
    >
      <p>{text}</p>
    </div>
  );
}

export default Paragraph;

import styles from "./styles.module.css";

import Placeholder from "../../assets/images/Placeholder.png";

import NavigationLink from "../../ui/NavLink/NavLink";

function InsightCard({ date, title, description, image, tag, url }) {
  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  return (
    <div className={styles.InsightCard}>
      <div className={styles.Image}>
        {image ? (
          <img src={image} alt={title} />
        ) : (
          <img src={Placeholder} alt={title} />
        )}
        <div className={styles.Overlay}>
          <p className={styles.tag}>
            {truncate(tag, tag.length > 15 ? 15 : tag.length)}
          </p>
        </div>
      </div>
      <div className={styles.Card}>
        <p className={styles.Date}>{date}</p>
        <div>
          <h3 className={styles.Title}>
            {truncate(title, title.length > 25 ? 25 : title.length)}
          </h3>
          <p className={styles.Description}>
            {truncate(
              description,
              description.length > 90 ? 90 : description.length
            )}
          </p>
        </div>
        <NavigationLink to={url}>
          <p>Read More</p>
        </NavigationLink>
      </div>
    </div>
  );
}

export default InsightCard;

import styles from "./styles.module.css";

import { useEffect } from "react";

import withVisibility from "../../../hoc/withVisibility/withVisibility";
import AboutSolution from "./components/AboutSolution/AboutSolution";
import KeyFeatures from "./components/KeyFeatures/KeyFeatures";
import OurApproach from "./components/OurApproach/OurApproach";
import FrequentlyAQ from "./components/FrequentlyAQ/FrequentlyAQ";

const VisibleAboutSolution = withVisibility(AboutSolution);
const VisibleFrequentlyAQ = withVisibility(FrequentlyAQ);
const VisibleKeyFeatures = withVisibility(KeyFeatures);
const VisibleOurApproach = withVisibility(OurApproach);

const TechnologyConsultation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.ComplianceConsultation}>
      <VisibleAboutSolution />
      <VisibleKeyFeatures />
      <VisibleOurApproach />
      <VisibleFrequentlyAQ />
    </div>
  );
};

export default TechnologyConsultation;

import React, { useState } from "react";
import styles from "./styles.module.css";
import Title from "../../../../../ui/Title/Title";
import Paragraph from "../../../../../ui/Paragrah/Paragraph";

function FrequentlyAQ() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "How Can I Get A Quote For Your Services?",
      answer: `You can request a quote by emailing to enquiries@vwebconsultancy.com or calling us directly.`,
    },
    {
      question: "Where Are Your Offices Located?",
      answer: `We have offices in the FCT, and London. See our office locations section for more details.`,
    },
    {
      question: "What's Your Business Working Hours?",
      answer: `Our business hours are from 8:00 AM to 5:00 PM, Monday to Friday.`,
    },
  ];

  return (
    <div className={styles.FrequentlyAQ} id="faq">
      <div className={styles.ContainerLeft}>
        <Title title="Frequently Asked Questions" color="#8FACE0" />
        <Paragraph
          text="Curious about who we are and what we do? Find answers here."
          color="#A8ACAF"
          fontSize={"24px"}
        />
      </div>
      <div className={styles.ContainerRight}>
        <div className={styles.FAQAccordion}>
          {faqs.map((faq, index) => (
            <React.Fragment key={index}>
              <button
                className={`${styles.FAQAccordionButton} ${
                  activeIndex === index ? styles.active : ""
                }`}
                onClick={() => toggleAccordion(index)}
              >
                <h3>Q. {faq.question}</h3>
              </button>
              <div
                className={`${styles.FAQAccordionContent} ${
                  activeIndex === index ? styles.active : ""
                }`}
              >
                <p>{faq.answer}</p>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FrequentlyAQ;

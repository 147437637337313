import styles from "./styles.module.css";

const Solution = ({ title, description, image }) => {
  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  return (
    <div className={styles.Solution}>
      <img src={image} alt={title} />
      <div className={styles.Content}>
        <h3>{truncate(title, title.length > 30 ? 30 : title.length)}</h3>
        <p>
          {truncate(
            description,
            description.length > 90 ? 90 : description.length
          )}
        </p>
      </div>
    </div>
  );
};

export default Solution;

import styles from "./styles.module.css";

import Title from "../../../../ui/Title/Title";
import CoreValueCard from "../../../../components/CoreValueCard/CoreValueCard";

function OurCoreValues() {
  const coreValues = [
    {
      title: "Innovation",
      description:
        "We are always looking for new ways to improve our products and services.",
    },
    {
      title: "Quality",
      description:
        "We are committed to providing the highest quality products and services.",
    },
    {
      title: "Integrity",
      description:
        "We are honest, transparent, and accountable in everything we do.",
    },
    {
      title: "Teamwork",
      description:
        "We work together to achieve our goals and deliver the best results.",
    },
    {
      title: "Customer Focus",
      description:
        "We put our customers first and strive to exceed their expectations.",
    },
  ];

  return (
    <div className={styles.OurCoreValues}>
      <Title title="Our Core Values" color="#8FACE0" />
      <div className={styles.CoreValuesList}>
        <div className={styles.CoreValues}>
          {coreValues.map((value, index) => (
            <CoreValueCard
              key={index}
              title={value.title}
              description={value.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default OurCoreValues;

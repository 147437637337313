import styles from "./styles.module.css";

import Title from "../../../../ui/Title/Title";
import SubTitle from "../../../../ui/SubTitle/SubTitle";

import AchievementCard from "../../../../ui/AchievementCard/AchievementCard";

function Impact() {
  return (
    <div className={styles.Impact}>
      <div className={styles.ImpactTitle}>
        <Title title="Our Impact" color="#0F131C" />
        <SubTitle
          title="See the tangible results we've achieved for our clients."
          color="#131C2B"
        />
      </div>
      <div className={styles.ImpactList}>
        <AchievementCard title="Ongoing Projects" stat="16+" />
        {/* <AchievementCard title="Clients Served" stat="15+" /> */}
        <AchievementCard title="Combined Years of Experience" stat="30+" />
        {/* <AchievementCard title="Client Rentention Rate" stat="95%" /> */}
      </div>
    </div>
  );
}

export default Impact;

import styles from "./styles.module.css";
import { useEffect, useState } from "react";

import Title from "../../../../../ui/Title/Title";
import InsightCard from "../../../../../components/InsightCard/InsightCard";

function AllNews() {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const articles = localStorage.getItem("articles");

    if (articles) {
      setArticles(JSON.parse(articles));
      // console.log(articles);
    } else {
      fetch(
        "https://api.mediastack.com/v1/news?access_key=fd11a72803e1351c1457cd3bbe3f0035&languages=en&categories=business,technology,science,-sports&limit=30&offset=30"
      )
        .then((response) => response.json())
        .then((data) => {
          setArticles(data);
          localStorage.setItem("articles", JSON.stringify(data));
        });
    }
  }, []);
  
  return (
    <div className={styles.AllNews}>
      <Title title="All News" color="#8FACE0" />
      <div className={styles.News}>
        {articles && articles.data ? (
          articles.data
            .slice(0, 10)
            .map((card, index) => (
              <InsightCard
                key={index}
                title={card.title}
                description={card.description}
                date={card.date}
                image={card.image}
                tag={card.source}
                url={card.url}
              />
            ))
        ) : (
          <p>No articles available.</p>
        )}
      </div>
    </div>
  );
}

export default AllNews;

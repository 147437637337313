import styles from "./styles.module.css";

import { useEffect } from "react";

import LatestNews from "./components/LatestNews/LatestNews";
import AllNews from "./components/AllNews/AllNews";
import ContactBarner from "../../../components/ContactBarner/ContactBarner";

import withVisibility from "../../../hoc/withVisibility/withVisibility";

const VisibleLatestNews = withVisibility(LatestNews);
const VisibleAllNews = withVisibility(AllNews);
const VisibleContactBarner = withVisibility(ContactBarner);

function IndustryNews() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.IndustryNews}>
      <div className={styles.News}>
        <VisibleLatestNews />
        <VisibleAllNews />
      </div>
      <VisibleContactBarner title="Ready to transform your Business?" />
    </div>
  );
}

export default IndustryNews;

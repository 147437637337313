import styles from "./styles.module.css";

import { useEffect } from "react";

import withVisibility from "../../../hoc/withVisibility/withVisibility";

import WeAre from "./WeAre/WeAre";
import OurMission from "./OurMission/OurMission";
import OurVision from "./OurVision/OurVision";
import OurCoreValues from "./OurCoreValues/OurCoreValues";
import ContactBarner from "../../../components/ContactBarner/ContactBarner";
import FrequentlyAQ from "./FrequentlyAQ/FrequentlyAQ";

const VisibleWeAre = withVisibility(WeAre);
const VisibleOurMission = withVisibility(OurMission);
const VisibleOurVision = withVisibility(OurVision);
const VisibleOurCoreValues = withVisibility(OurCoreValues);
const VisibleContactBarner = withVisibility(ContactBarner);
const VisibleFrequentlyAQ = withVisibility(FrequentlyAQ);

function AboutUs() {
   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);
  return (
    <div className={styles.AboutUs}>
      <VisibleWeAre />
      <VisibleOurMission />
      <VisibleOurVision />
      <VisibleOurCoreValues />
      <VisibleContactBarner title="Do you still have questions?" />
      <VisibleFrequentlyAQ />
    </div>
  );
}

export default AboutUs;

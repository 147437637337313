import styles from "./styles.module.css";

import Title from "../../../../../ui/Title/Title";
import Title2 from "../../../../../ui/Title2/Title2";
import Paragraph from "../../../../../ui/Paragrah/Paragraph";

// import placeholder from "../../../../assets/images/Placeholder.png";

import complianceConsultation from "../../../../../assets/images/fintechConsultation.png";

function AboutSolution({ backgroundColor }) {
  return (
    <div className={styles.WeAre} style={{ backgroundColor: backgroundColor }}>
      <div className={styles.WeAreContent}>
        <Title title="Fintech Consultation" color="#8FACE0" />

        <Paragraph
          text="Our Fintech Consultation service empowers financial institutions and fintech companies to innovate and adapt in a rapidly evolving market. From digital banking to payment solutions, we help you stay ahead of the curve with cutting-edge technology and strategic insights."
          color="#A8ACAF"
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Title2 title="Benefits" color="#8FACE0" />
          <ul
            style={{
              color: "#A8ACAF",
              textAlign: "left",
              lineHeight: "2.0",
              fontSize: "16px",
            }}
          >
            <li>
              <span style={{ color: "#8FACE0", fontWeight: "600" }}>
                Innovative Solutions:
              </span>{" "}
              Stay at the forefront of financial technology with our expert
              guidance.
            </li>
            <li>
              <span style={{ color: "#8FACE0", fontWeight: "600" }}>
                Market Expertise:
              </span>{" "}
              Leverage our deep understanding of the fintech landscape.
            </li>
            <li>
              <span style={{ color: "#8FACE0", fontWeight: "600" }}>
                Compliance Support:
              </span>{" "}
              Navigate regulatory requirements with ease.
            </li>
            <li>
              <span style={{ color: "#8FACE0", fontWeight: "600" }}>
                Scalability:
              </span>{" "}
              Build scalable financial solutions that grow with your business.
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.WeAreImage}>
        <img src={complianceConsultation} alt="Placeholder" />
      </div>
    </div>
  );
}

export default AboutSolution;

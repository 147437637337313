import styles from "./styles.module.css";
import { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import UniversalHeader from "../../components/UniversalHeader/UniversalHeader";
import SubNavLink from "../../ui/SubNavLink/SubNav";

import IndustryNews from "./IndustryNews/IndustryNews";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "./TermsOfUse/TermsOfUse";
import Blogs from "./Blogs/Blogs";

import withVisibility from "../../hoc/withVisibility/withVisibility";

function Navigation() {
  return (
    <div className={styles.Navigation}>
      <SubNavLink title="Blogs" to="" />
      <SubNavLink title="Industry News" to="industry-news" />
      <SubNavLink title="Privacy Policy" to="privacy-policy" />
      <SubNavLink title="Terms of Use" to="terms-of-use" />
    </div>
  );
}

const VisibleUniversalHeader = withVisibility(UniversalHeader);
const VisibleNavigation = withVisibility(Navigation);

function Resources() {
  const [title, setTitle] = useState("About Us");
  const [subtitle, setSubtitle] = useState(
    "Learn more about our history, mission, vision, and values that drive our success."
  );

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/resources/privacy-policy") {
      setTitle("Privacy Policy");
      setSubtitle(
        "Learn more about our privacy policy and how we protect your personal information."
      );
    } else if (location.pathname === "/resources/terms-of-use") {
      setTitle("Terms of Use");
      setSubtitle(
        "Learn more about our terms of use and how we protect your personal information."
      );
    } else if (location.pathname === "/resources/industry-news") {
      setTitle("Industry News");
      setSubtitle(
        "Learn more about our history, mission, vision, and values that drive our success."
      );
    } else {
      setTitle("Blogs");
      setSubtitle(
        "Learn more about our history, mission, vision, and values that drive our success."
      );
    }
  }, [location.pathname]);



  return (
    <div className={styles.WhoWeAre}>
      <VisibleUniversalHeader title={title} subtitle={subtitle} />
      <VisibleNavigation />
      <div className={styles.Content}>
        <Routes>
          <Route path="/" element={<Blogs />} />
          <Route path="industry-news" element={<IndustryNews />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-of-use" element={<TermsOfUse />} />
        </Routes>
      </div>
    </div>
  );
}

export default Resources;

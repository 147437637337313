import styles from "./styles.module.css";
import { NavLink } from "react-router-dom";

function SubNavLink({ title = "SubNav", to = "/", children }) {
  return (
    <NavLink
      to={to}
      end
      className={({ isActive }) =>
        `${styles.SubNav} ${isActive ? styles.active : ""}`
      }
    >
      <h3>{title}</h3>
    </NavLink>
  );
}

export default SubNavLink;

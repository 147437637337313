import styles from "./styles.module.css";

import Title from "../../ui/Title/Title";

function NoPost({ title, post }) {
  return (
    <div className={styles.NoPost}>
      <div className={styles.Title}>
        <Title title={title} color="#8FACE0" />
      </div>
      <p className={styles.Text1}>Oops!</p>
      <p className={styles.Text2}>
        It looks like there aren't any {post} posts to display at the moment.
      </p>
      <p className={styles.Text2}>Please check back later for updates.</p>
    </div>
  );
}

export default NoPost;

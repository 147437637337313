import styles from "./styles.module.css";
import { useEffect } from "react";

import UniversalHeader from "../../components/UniversalHeader/UniversalHeader";
import ContactBarner from "../../components/ContactBarner/ContactBarner";
import SearchBar from "./components/SearchBar/SearchBar";
import Aside from "./components/Aside/Aside";
import JobOpenings from "./JobOpenings/JobOpenings";

import withVisibility from "../../hoc/withVisibility/withVisibility";

const VisibleUniversalHeader = withVisibility(UniversalHeader);
const VisibleContactBarner = withVisibility(ContactBarner);
const VisibleSearchBar = withVisibility(SearchBar);
const VisibleAside = withVisibility(Aside);
const VisibleJobOpenings = withVisibility(JobOpenings);

function Career() {
   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);
  return (
    <div className={styles.Career}>
      <VisibleUniversalHeader
        title="Career"
        subtitle="
        We are looking for talented people to join our team.
      "
      />
      <div className={styles.CareerContent}>
        <VisibleSearchBar />
        <div className={styles.CareerContentMain}>
          <VisibleAside />
          <VisibleJobOpenings />
        </div>
      </div>
      <VisibleContactBarner title="Ready to transform your Business?" />
    </div>
  );
}

export default Career;

import styles from "./styles.module.css";

function CoreValueCard({ title = "Core Value", description = "Description" }) {
  return (
    <div className={styles.CoreValueCard}>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
}

export default CoreValueCard;
import styles from "./styles.module.css";

function PrivacyAndTerms({ title, paragraph }) {
  return (
    <div className={styles.PrivacyAndTerms}>
      <h3>{title}</h3>
      <p>{paragraph}</p>
    </div>
  );
}

export default PrivacyAndTerms;

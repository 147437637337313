import styles from "./styles.module.css";
import { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import UniversalHeader from "../../components/UniversalHeader/UniversalHeader";
import SubNavLink from "../../ui/SubNavLink/SubNav";

import ComplianceConsultation from "./ComplianceConsultation/ComplianceConsultation";
import FintechConsultation from "./FintechConsultation/FintechConsultation";
import Revenue from "./Revenue/Revenue";
import TechnologyConsultation from "./TechnologyConsultation/TechnologyConsultation";

import withVisibility from "../../hoc/withVisibility/withVisibility";

function Navigation() {
  return (
    <div className={styles.Navigation}>
      <SubNavLink title="Compliance" to="" />
      <SubNavLink title="Fintech" to="fintech-consultation" />
      <SubNavLink title="Revenue" to="revenue" />
      <SubNavLink
        title="Technology"
        to="technology-consultation"
      />
    </div>
  );
}

const VisibleUniversalHeader = withVisibility(UniversalHeader);
const VisibleNavigation = withVisibility(Navigation);

function SolutionsWeProvide() {
  const [title, setTitle] = useState("Compliance Consultation");
  const [subtitle, setSubtitle] = useState(
    "Get expert advice on financial technology solutions."
  );

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/solutions-we-provide/compliance-consultation") {
      setTitle("Compliance Consultation");
      setSubtitle("Get expert advice on compliance consultation.");
    } else if (
      location.pathname === "/solutions-we-provide/fintech-consultation"
    ) {
      setTitle("Fintech Consultation");
      setSubtitle(
        "Get expert advice on financial technology solutions."
      );
    } else if (location.pathname === "/solutions-we-provide/revenue") {
      setTitle("Revenue");
      setSubtitle(
        "Get expert advice on revenue."
      );
    } else {
      setTitle("Technology Consultation");
      setSubtitle(
        "Get expert advice on technology consultation."
      );
    }
  }, [location.pathname]);

  return (
    <div className={styles.WhoWeAre}>
      <VisibleUniversalHeader title={title} subtitle={subtitle} />
      <VisibleNavigation />
      <div className={styles.Content}>
        <Routes>
          <Route
            path="/"
            element={<ComplianceConsultation />}
          />
          <Route
            path="/fintech-consultation"
            element={<FintechConsultation />}
          />
          <Route path="/revenue" element={<Revenue />} />
          <Route
            path="/technology-consultation"
            element={<TechnologyConsultation />}
          />
        </Routes>
      </div>
    </div>
  );
}

export default SolutionsWeProvide;

import styles from "./styles.module.css";

function Button({ text = "Button", onClick, padding, backgroundColor, color }) {
  return (
    <button
      className={styles.Button}
      onClick={onClick}
      style={{
        padding: padding,
        backgroundColor: backgroundColor,
        color: color,
      }}
    >
      {text}
    </button>
  );
}

export default Button;

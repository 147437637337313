import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import Title from "../../../../ui/Title/Title";
import SubTitle from "../../../../ui/SubTitle/SubTitle";
import Solution from "../../../../components/Solution/Solution";

// Import images
import technologyConsultation from "../../../../assets/images/technologyConsultation.png";
import fintechConsultation from "../../../../assets/images/fintechConsultation.png";
import complianceConsultation from "../../../../assets/images/complianceConsultation.png";
import revenueConsultation from "../../../../assets/images/revenueConsultation.png";

const Solutions = () => {
  const scrollContainerRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const solutions = [
    {
      title: "Technology Consultation",
      description:
        "Revolutionarise your business with cutting-edge technology solutions. We analyse, strategise, and implement IT projects that align with your business goals.",
      image: technologyConsultation,
    },
    {
      title: "Fintech Consultation",
      description:
        "Harness the power of fintech to optimise your financial processes. Our expertise includes blockchain, digital payment, and compliance with financial regulations.",
      image: fintechConsultation,
    },
    {
      title: "Compliance Consultation",
      description:
        "We help you navigate complex regulations, ensure compliance, and mitigate risks.",
      image: complianceConsultation,
    },
    {
      title: "Revenue",
      description:
        "We help you build digital products that solve real problems and provide value to your customers.",
      image: revenueConsultation,
    },
  ];

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    const handleScroll = () => {
      const scrollLeft = scrollContainer.scrollLeft;
      const containerWidth = scrollContainer.clientWidth;
      const maxScrollLeft = scrollContainer.scrollWidth - containerWidth;

      const newIndex =
        scrollLeft >= maxScrollLeft
          ? solutions.length - 1
          : Math.round(scrollLeft / containerWidth);

      setActiveIndex(newIndex);
    };

    scrollContainer.addEventListener("scroll", handleScroll);

    return () => scrollContainer.removeEventListener("scroll", handleScroll);
  }, [solutions.length]);

  const handleIndicatorClick = (index) => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    const containerWidth = scrollContainer.clientWidth;
    scrollContainer.scrollTo({
      left: containerWidth * index,
      behavior: "smooth",
    });
  };

  return (
    <div className={styles.Solutions}>
      <div className={styles.Title}>
        <Title title="Our Solutions" color="#8FACE0" />
        <SubTitle title="Discover how we transform businesses with our specialised consultancy services." />
      </div>
      <div className={styles.SolutionsList} ref={scrollContainerRef}>
        {solutions.map((solution, index) => (
          <div className={styles.SolutionItem} key={index}>
            <Solution
              title={solution.title}
              description={solution.description}
              image={solution.image}
            />
          </div>
        ))}
      </div>
      <div className={styles.ScrollIndicators}>
        {solutions.map((_, index) => (
          <div
            key={index}
            className={`${styles.Indicator} ${
              index === activeIndex ? styles.Active : ""
            }`}
            onClick={() => handleIndicatorClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Solutions;

import styles from "./styles.module.css";

function ContactCard({ title, office, address, phone, email }) {
  return (
    <div className={styles.ContactCard}>
      <h3>{title}</h3>
      <div className={styles.ContactText}>
        <p className={styles.TextTitle}>Office:</p>
        <p className={styles.TextContent}>{office}</p>
      </div>
      <div className={styles.ContactText}>
        <p className={styles.TextTitle}>Address:</p>
        <p className={styles.TextContent}>{address}</p>
      </div>
      <div className={styles.ContactText}>
        <p className={styles.TextTitle}>Phone Number:</p>
        <p className={styles.TextContent}>{phone}</p>
      </div>
      <div className={styles.ContactText}>
        <p className={styles.TextTitle}>Email Address:</p>
        <p className={styles.TextContent}>{email}</p>
      </div>
    </div>
  );
}

export default ContactCard;

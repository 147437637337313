import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import withVisibility from "./hoc/withVisibility/withVisibility";
//components
import NavBar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";

//pages
import Home from "./pages/Home/Home";
import WhoWeAre from "./pages/WhoWeAre/WhoWeAre";
import Resources from "./pages/Resources/Resources";
import Career from "./pages/Career/Career";
import ContactUs from "./pages/ContactUs/ContactUs";
import SolutionsWeProvide from "./pages/SolutionsWeProvide/SolutionsWeProvide";

const VisibleFooter = withVisibility(Footer);

function App() {
  return (
    <div className="App">
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/who-we-are/*" element={<WhoWeAre />} />
          <Route path="/resources/*" element={<Resources />} />
          <Route
            path="/solutions-we-provide/*"
            element={<SolutionsWeProvide />}
          />
          <Route path="/career" element={<Career />} />
          <Route path="/contact" element={<ContactUs />} />
        </Routes>
        <VisibleFooter />
      </Router>
    </div>
  );
}

export default App;

import styles from "./styles.module.css";

import { FaSearch } from "react-icons/fa";

function SearchBar() {
  return (
    <div className={styles.SearchBar}>
      <button>Search Job</button>
      <div className={styles.Input}>
        <FaSearch color="#D9E3F8" />
        <input type="text" placeholder="Enter a keyword" />
      </div>
    </div>
  );
}

export default SearchBar;

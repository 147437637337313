import styles from "./styles.module.css";

import { useNavigate } from "react-router-dom";

import Button from "../../ui/Button/Button";

function ContactBarner({ title }) {
  const navigate = useNavigate();

  return (
    <div className={styles.ContactBarner}>
      <div className={styles.ContactBarnerContent}>
        <h3>{title}</h3>
        <Button text="Contact Us" onClick={() => navigate("/contact")} />
      </div>
    </div>
  );
}

export default ContactBarner;

import styles from "./styles.module.css";
import logo from "../../assets/logo/logoPrimaryWhite.svg";

// import XIcon from "../../assets/icons/XIcon.svg";
// import InstagramIcon from "../../assets/icons/InstagramIcon.svg";
import LinkedInIcon from "../../assets/icons/LinkedInIcon.svg";
// import YouTubeIcon from "../../assets/icons/YouTubeIcon.svg";

import NavigationLink from "../../ui/NavLink/NavLink";

function Footer() {
  return (
    <div className={styles.Footer}>
      <div className={styles.FooterContentTop}>
        <div className={styles.Interaction}>
          <div className={styles.Socials}>
            <NavigationLink to="/" icon={false}>
              <div className={styles.Logo}>
                <img src={logo} alt="Logo" />
              </div>
            </NavigationLink>
            <div className={styles.SocialsIcons}>
              {/* <img src={XIcon} alt="X" /> */}
              <a
                href="https://www.linkedin.com/in/voguewebglobal-7a71a6327"
                target="blank"
              >
                <img src={LinkedInIcon} alt="LinkedIn Icon" />
              </a>
              {/* <img src={InstagramIcon} alt="Instagram" /> */}
              {/* <img src={YouTubeIcon} alt="Youtube" /> */}
            </div>
          </div>
          <div className={styles.Newsletter}>
            <h3>Subscribe to our Newsletter</h3>
            <div className={styles.Subscribe}>
              <input type="email" placeholder="Enter your email" />
              <button>Subscribe</button>
            </div>
          </div>
        </div>
        <div className={styles.Links}>
          <div className={styles.Link}>
            <h3>Who We Are</h3>
            <div className={styles.LinkGroup}>
              <NavigationLink to="/who-we-are">About Us</NavigationLink>
              {/* <NavigationLink to="/team">Team Members</NavigationLink> */}
              {/* <NavigationLink to="/testimonials">Testimonials</NavigationLink> */}
              <NavigationLink to="/who-we-are/faq">FAQs</NavigationLink>
            </div>
          </div>
          <div className={styles.Link}>
            <h3>Our Solutions</h3>
            <div className={styles.LinkGroup}>
              <NavigationLink to="/solutions-we-provide/technology-consultation">
                Technology
              </NavigationLink>
              <NavigationLink to="/solutions-we-provide/fintech-consultation">
                Fintech
              </NavigationLink>
              <NavigationLink to="/solutions-we-provide/">
                Compliance
              </NavigationLink>
              <NavigationLink to="/solutions-we-provide/revenue">
                Revenue
              </NavigationLink>
            </div>
          </div>
          <div className={styles.Link}>
            <h3>Resources</h3>
            <div className={styles.LinkGroup}>
              <NavigationLink to="/resources/industry-news">
                Industry News
              </NavigationLink>
              <NavigationLink to="/resources">Blogs</NavigationLink>
              {/* <NavigationLink to="/case-study">Case Study</NavigationLink> */}
              <NavigationLink to="/resources/privacy-policy">
                Privacy Policy
              </NavigationLink>
              <NavigationLink to="/resources/terms-of-use">
                Terms of Use
              </NavigationLink>
            </div>
          </div>
          <div className={styles.Link}>
            <h3>Career</h3>
            <div className={styles.LinkGroup}>
              <NavigationLink to="/career">Career</NavigationLink>
            </div>
          </div>
          <div className={styles.Link}>
            <h3>Contact Us</h3>
            <div className={styles.LinkGroup}>
              <NavigationLink to="/contact">Contact Us</NavigationLink>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.FooterContentBottom}>
        <p className={styles.FooterText}>
          © 2024 Vogue Web Solutions. All rights reserved.
        </p>
        <p className={styles.Divider}>|</p>
        <NavigationLink to="/resources/privacy-policy">
          Privacy Policy
        </NavigationLink>
        <p>|</p>
        <NavigationLink to="/resources/terms-of-use">
          Terms of Service
        </NavigationLink>
      </div>
      <div>
        <p className={styles.FooterText} style={{ fontSize: "12px" }}>
          A brand owned by Vogue Web Solutions limited UK
        </p>
      </div>
    </div>
  );
}

export default Footer;

import styles from "./styles.module.css";

import Title from "../../../../../ui/Title/Title";
import SubTitle from "../../../../../ui/SubTitle/SubTitle";
import CoreValueCard from "../../../../../components/CoreValueCard/CoreValueCard";

function OurApproach() {
  const coreValues = [
    {
      title: "Regulatory Assessment",
      description:
        "We begin with a comprehensive assessment of your current compliance status, and identifying areas of risk and opportunities.",
    },
    {
      title: "Strategy Development",
      description:
        "We work with you to develop a compliance strategy that aligns with your business goals and meets industry standards.",
    },
    {
      title: "Implementation",
      description:
        "Our team assists in implementing the necessary changes, providing training and resources to ensure your team is fully compliant.",
    },
    {
      title: "On Going Support",
      description:
        "We offer ongoing monitoring and updates to ensure your business continues to meet evolving regulatory requirements.",
    },
  ];

  return (
    <div className={styles.OurCoreValues}>
      <Title title="Our Approach" color="#8FACE0" />
      <div className={styles.OurApproachContent}>
        <SubTitle
          title="Our approach to compliance consultation is thorough and proactive, ensuring your business is always a step ahead in meeting regulatory requirements."
          color="#A8ACAF"
        />
      </div>
      <div className={styles.CoreValuesList}>
        <div className={styles.CoreValues}>
          {coreValues.map((value, index) => (
            <CoreValueCard
              key={index}
              title={value.title}
              description={value.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default OurApproach;

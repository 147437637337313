import styles from "./styles.module.css";

function Title({ title = "Title", color, fontSize }) {
  return (
    <div className={styles.Title} style={{ color: color, fontSize: fontSize }}>
      <div className={styles.Line} style={{ backgroundColor: color }}></div>
      <h3>{title}</h3>
    </div>
  );
}

export default Title;

import styles from "./styles.module.css";

import Title from "../../../../../ui/Title/Title";
import SubTitle from "../../../../../ui/SubTitle/SubTitle";
import CoreValueCard from "../../../../../components/CoreValueCard/CoreValueCard";

function OurApproach() {
  const coreValues = [
    {
      title: "Revenue Assessment",
      description:
        "We start with a thorough analysis of your current revenue streams, identifying strengths, weaknesses, and opportunities for improvement.",
    },
    {
      title: "Strategy Development",
      description:
        "Collaborating with your team, we develop customised revenue strategies that align with your business objectives.",
    },
    {
      title: "Implementation",
      description:
        "We assist in the execution of these strategies, providing the tools and support needed to achieve your revenue goals.",
    },
    {
      title: "Performance Monitoring",
      description:
        "Ongoing monitoring and adjustment of strategies to ensure continued success and adaptation to market changes.",
    },
  ];

  return (
    <div className={styles.OurCoreValues}>
      <Title title="Our Approach" color="#8FACE0" />
      <div className={styles.OurApproachContent}>
        <SubTitle
          title="Our approach is comprehensive and client-focused, ensuring that every step is aligned with your business goals and technological needs."
          color="#A8ACAF"
        />
      </div>
      <div className={styles.CoreValuesList}>
        <div className={styles.CoreValues}>
          {coreValues.map((value, index) => (
            <CoreValueCard
              key={index}
              title={value.title}
              description={value.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default OurApproach;

import styles from "./styles.module.css";

import Title from "../../../../ui/Title/Title";
import Paragraph from "../../../../ui/Paragrah/Paragraph";

function OurVision() {
  return (
    <div className={styles.OurVision}>
      <div className={styles.OurVisionContent}>
        <Title title="Our Vision" color="#8FACE0" />
        <Paragraph
          text="Our vision is to be the leading partner for Nations and businesses in
the digital age, driving innovation and regulatory excellence across
Africa and beyond. We aim to create a fair, transparent, and thriving
digital economy that benefits all stakeholders, while fostering growth and
development in every market we serve.
"
          color="#A8ACAF"
        />
      </div>
    </div>
  );
}

export default OurVision;

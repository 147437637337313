import styles from "./styles.module.css";

function AchievementCard({ title = "Title", stat = "Stat", color }) {
  return (
    <div className={styles.AchievementCard} style={{ backgroundColor: color }}>
      <h3>{title}</h3>
      <h1>{stat}</h1>
    </div>
  );
}

export default AchievementCard;
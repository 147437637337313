import styles from "./styles.module.css";
import Button from "../../../../ui/Button/Button";
import ButtonAlternate from "../../../../ui/ButtonAlternate/ButtonAlternate";
import NavigationLink from "../../../../ui/NavLink/NavLink";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

function Header() {
  const particlesInit = async (main) => {
    // Initialize tsParticles instance
    await loadFull(main);
  };

  const particlesOptions = {
    particles: {
      number: {
        value: 80,
        density: {
          enable: true,
          value_area: 800,
        },
      },
      color: {
        value: "#8FACE0",
      },
      shape: {
        type: "line",
      },
      opacity: {
        value: 0.5,
        random: false,
        anim: {
          enable: false,
        },
      },
      size: {
        value: 2,
        random: true,
        anim: {
          enable: false,
        },
      },
      line_linked: {
        enable: true,
        distance: 150,
        color: "#8FACE0",
        opacity: 0.4,
        width: 2,
      },
      move: {
        enable: true,
        speed: 2,
        direction: "none",
        random: false,
        straight: false,
        out_mode: "out",
        attract: {
          enable: false,
        },
      },
    },
    interactivity: {
      detect_on: "canvas",
      events: {
        onhover: {
          enable: true,
          mode: "repulse",
        },
        onclick: {
          enable: true,
          mode: "push",
        },
        resize: true,
      },
      modes: {
        repulse: {
          distance: 100,
          duration: 0.4,
        },
        push: {
          particles_nb: 4,
        },
      },
    },
    retina_detect: true,
  };

  return (
    <header className={styles.Header}>
      <Particles
        id="tsparticles"
        init={particlesInit}
        options={particlesOptions}
        className={styles.Particles}
      />
      <h1>
        Accelerating Business
        <br />
        Excellence!
      </h1>
      <p>
        Specialised consultancy in Digital Transformation,
        <br />
        Financial Services, Regulatory Compliance, and
        <br />
        Strategic Growth.
      </p>

      <div className={styles.Buttons}>
        <NavigationLink to="/who-we-are">
          <Button text="Learn More" padding={"12px 80px"} />
        </NavigationLink>
        <NavigationLink to="/contact">
          <ButtonAlternate text="Contact Us" padding={"12px 80px"} />
        </NavigationLink>
      </div>
    </header>
  );
}

export default Header;

import styles from "./styles.module.css";

import PrivacyAndTerms from "../../../../Home/components/PrivacyAndTerms/PrivacyAndTerms";

function PolicyContainer() {
  return (
    <div className={styles.PolicyContainer}>
      <PrivacyAndTerms
        title="About Our Privacy Policy"
        paragraph="Our Privacy Policy is designed to help you understand how we collect and use the personal information you provide to us and to assist you in making informed decisions when using our site and our products and services."
      />

      <PrivacyAndTerms
        title="What Information We Collect"
        paragraph="When you visit our website, you may provide us with two types of information: personal information you knowingly choose to disclose that is collected on an individual basis and website use information collected on an aggregate basis as you and others browse our website."
      />

      <PrivacyAndTerms
        title="How We Use Your Information"
        paragraph="We use the information you provide about yourself to fulfill your requests for our products, programs, and services, to respond to your inquiries about our offerings, and to offer you other products, programs or services that we believe may be of interest to you."
      />

      <PrivacyAndTerms
        title="How We Protect Your Information"
        paragraph="We implement a variety of security measures to maintain the safety of your personal information. Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential."
      />

      <PrivacyAndTerms
        title="How We Share Your Information"
        paragraph="We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential."
      />

      <PrivacyAndTerms
        title="How to Contact Us"
        paragraph="Should you have other questions or concerns about these privacy policies, please send us an email at"
      />
    </div>
  );
}

export default PolicyContainer;

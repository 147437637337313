import styles from "./styles.module.css";

function Heading({ title = "SubTitle", color }) {
  return (
    <div className={styles.Heading} style={{ color: color }}>
      <h1>{title}</h1>
    </div>
  );
}

export default Heading;

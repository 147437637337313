import styles from "./styles.module.css";

import { useEffect } from "react";

import ContactSection from "./components/ContactSection/ContactSection";
import UniversalHeader from "../../components/UniversalHeader/UniversalHeader";

import withVisibility from "../../hoc/withVisibility/withVisibility";

const VisibleUniversalHeader = withVisibility(UniversalHeader);
const VisibleContactSection = withVisibility(ContactSection);

function ContactUs() {
   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);
  return (
    <div className={styles.ContactUs}>
      <VisibleUniversalHeader
        title="Contact Us"
        subtitle="We’re here to help and answer any questions you might have. We look forward to hearing from you!"
      />
      <VisibleContactSection />
    </div>
  );
}

export default ContactUs;

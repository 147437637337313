import styles from "./styles.module.css";

const SolutionCard = ({ title }) => {
  return (
    <div className={styles.SolutionCard}>
      <li style={{ color: "#8FACE0" }}>{title}</li>
    </div>
  );
};

const KeyFeatures = () => {
  const keyFeatures = [
    "Revenue Forecasting",
    "Price Optimization",
    "Sale Strategy Enhancement",
    "Customer Lifetime Value (CLV) Analysis",
  ];
  return (
    <div className={styles.KeyFeatures}>
      <div className={styles.Left}>
        <h2>Key Features</h2>
      </div>
      <div className={styles.Right}>
        {keyFeatures.map((feature, index) => (
          <SolutionCard key={index} title={feature} />
        ))}
      </div>
    </div>
  );
};

export default KeyFeatures;

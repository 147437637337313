import styles from "./styles.module.css";

import NoPost from "../../../components/NoPost/NoPost";

function JobOpenings() {
  return (
    <div className={styles.JobOpenings}>
      <NoPost title="Job Openings" post="job" />
    </div>
  );
}

export default JobOpenings;

import styles from "./styles.module.css";

import Title from "../../../../ui/Title/Title";
import Paragraph from "../../../../ui/Paragrah/Paragraph";

function OurMission() {
  return (
    <div className={styles.OurMission}>
      <div className={styles.OurMissionContent}>
        <Title title="Our Mission" color="#8FACE0" />
        <Paragraph
          text="Our mission is to empower the Public sector and businesses by providing expert
guidance in Fintech, Compliance, Tax, and Technological Architecture. We
strive to enhance regulatory frameworks, ensure the fair collection of
revenues, and support the sustainable growth of the digital economy.
Through our tailored solutions, we help our clients mitigate risks,
optimise operations, and achieve long-term success.
"
          color="#A8ACAF"
        />
      </div>
    </div>
  );
}

export default OurMission;

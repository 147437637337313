import styles from "./styles.module.css";
import { useEffect } from "react";

import NoPost from "../../../components/NoPost/NoPost";
import ContactBarner from "../../../components/ContactBarner/ContactBarner";

import withVisibility from "../../../hoc/withVisibility/withVisibility";

const VisibleNoPost = withVisibility(NoPost);
const VisibleContactBarner = withVisibility(ContactBarner);

function Blogs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.Blogs}>
      <div className={styles.BlogsContent}>
        <VisibleNoPost title="Latest Blogs" post="blog" />
      </div>
      <VisibleContactBarner title="Ready to transform your Business?" />
    </div>
  );
}

export default Blogs;

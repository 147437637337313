import styles from "./styles.module.css";

import Title from "../../../../../ui/Title/Title";
import Title2 from "../../../../../ui/Title2/Title2";
import Paragraph from "../../../../../ui/Paragrah/Paragraph";

// import placeholder from "../../../../assets/images/Placeholder.png";

import complianceConsultation from "../../../../../assets/images/complianceConsultation2.png";

function AboutSolution({ backgroundColor }) {
  return (
    <div className={styles.WeAre} style={{ backgroundColor: backgroundColor }}>
      <div className={styles.WeAreContent}>
        <Title title="Compliance Consultation" color="#8FACE0" />
        <Paragraph
          text="Our Compliance Consultation service is focused on helping businesses navigate the complex landscape of industry regulations and standards. We provide expert guidance to ensure your operations remain compliant, reducing the risk of legal issues and fostering trust with clients and stakeholders."
          color="#A8ACAF"
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Title2 title="Benefits" color="#8FACE0" />
          <ul
            style={{
              color: "#A8ACAF",
              textAlign: "left",
              lineHeight: "2.0",
              fontSize: "16px",
            }}
          >
            <li>
              <span style={{ color: "#8FACE0", fontWeight: "600" }}>
                Regulatory Expertise:
              </span>{" "}
              Stay up-to-date with the latest industry regulations and
              standards.
            </li>
            <li>
              <span style={{ color: "#8FACE0", fontWeight: "600" }}>
                Risk Mitigation:
              </span>{" "}
              Identify and address potential compliance risks before they become
              issues.
            </li>
            <li>
              <span style={{ color: "#8FACE0", fontWeight: "600" }}>
                Customised Solutions:
              </span>{" "}
              Tailored compliance strategies that fit your business needs.
            </li>
            <li>
              <span style={{ color: "#8FACE0", fontWeight: "600" }}>
                Ongoing Support:
              </span>{" "}
              Continuous monitoring and support to maintain compliance over
              time.
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.WeAreImage}>
        <img src={complianceConsultation} alt="Placeholder" />
      </div>
    </div>
  );
}

export default AboutSolution;

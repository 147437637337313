import styles from "./styles.module.css";

import Title from "../../../../../ui/Title/Title";
import Title2 from "../../../../../ui/Title2/Title2";
import Paragraph from "../../../../../ui/Paragrah/Paragraph";

// import placeholder from "../../../../assets/images/Placeholder.png";

import revenueConsultation from "../../../../../assets/images/revenueConsultation2.png";

function AboutSolution({ backgroundColor }) {
  return (
    <div className={styles.WeAre} style={{ backgroundColor: backgroundColor }}>
      <div className={styles.WeAreContent}>
        <Title title="Technology Consultation" color="#8FACE0" />
        <Paragraph
          text="Our Revenue service is dedicated to helping businesses optimise their revenue streams and maximise profitability. By leveraging data-driven strategies and industry expertise, we provide the insights needed to enhance your financial performance and achieve sustainable growth."
          color="#A8ACAF"
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Title2 title="Benefits" color="#8FACE0" />
          <ul
            style={{
              color: "#A8ACAF",
              textAlign: "left",
              lineHeight: "2.0",
              fontSize: "16px",
            }}
          >
            <li>
              <span style={{ color: "#8FACE0", fontWeight: "600" }}>
                Strategic Planning:
              </span>{" "}
              Develop comprehensive revenue strategies that align with your
              business goals.
            </li>
            <li>
              <span style={{ color: "#8FACE0", fontWeight: "600" }}>
                Optimisation:
              </span>
              Profit Identify and capitalise on opportunities to increase
              profitability.
            </li>
            <li>
              <span style={{ color: "#8FACE0", fontWeight: "600" }}>
                Data-Driven Insights:
              </span>{" "}
              Utilise advanced analytics to inform revenue decisions.
            </li>
            <li>
              <span style={{ color: "#8FACE0", fontWeight: "600" }}>
                Sustainable Growth:
              </span>{" "}
              Implement strategies that drive long-term financial success.
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.WeAreImage}>
        <img src={revenueConsultation} alt="Placeholder" />
      </div>
    </div>
  );
}

export default AboutSolution;

import { useState, useRef } from "react";
import styles from "./styles.module.css";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";

const Title = ({ title = "Title", onClick, isOpen }) => {
  return (
    <div className={styles.Title} onClick={onClick}>
      <h3>{title}</h3>
      {isOpen ? <FaAngleUp /> : <FaAngleDown />}
    </div>
  );
};

const Options = ({ children }) => {
  return (
    <div className={styles.Options}>
      <div className={styles.Option}>
        <input type="checkbox" id="option1" name="option1" />
        <label htmlFor="option1">Option 1</label>
      </div>
      <div className={styles.Option}>
        <input type="checkbox" id="option2" name="option2" />
        <label htmlFor="option2">Option 2</label>
      </div>
      <div className={styles.Option}>
        <input type="checkbox" id="option3" name="option3" />
        <label htmlFor="option3">Option 3</label>
      </div>
      <div className={styles.Option}>
        <input type="checkbox" id="option4" name="option4" />
        <label htmlFor="option4">Option 4</label>
      </div>
    </div>
  );
};

const AccordionSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState("0px");
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    setHeight(isOpen ? "0px" : `${contentRef.current.scrollHeight}px`);
  };

  return (
    <div className={styles.Section}>
      <Title title={title} onClick={toggleAccordion} isOpen={isOpen} />
      <div
        ref={contentRef}
        style={{ height: height }}
        className={styles.Content}
      >
        {children}
      </div>
    </div>
  );
};

function Aside() {
  return (
    <div className={styles.Aside}>
      <AccordionSection title="Job Type">
        <Options />
      </AccordionSection>
      <AccordionSection title="Location">
        <Options />
      </AccordionSection>
      <AccordionSection title="Experience">
        <Options />
      </AccordionSection>
      <AccordionSection title="Salary">
        <Options />
      </AccordionSection>
      <div className={styles.Filter}>
        <button className={styles.ApplyFilter}>Filter Jobs</button>
        <button className={styles.ResetFilter}>
          Reset <IoClose color="#A8ACAF" />
        </button>
      </div>
    </div>
  );
}

export default Aside;

import styles from "./styles.module.css";

import Title from "../../../../../ui/Title/Title";
import SubTitle from "../../../../../ui/SubTitle/SubTitle";
import CoreValueCard from "../../../../../components/CoreValueCard/CoreValueCard";

function OurApproach() {
  const coreValues = [
    {
      title: "Assessment",
      description:
        "We begin by thoroughly assessing your current technology landscape to understand your needs and identify areas for improvement.",
    },
    {
      title: "Strategy Development",
      description:
        "Our team creates a customised technology roadmap that aligns with your business goals.",
    },
    {
      title: "Implementation",
      description:
        "We assist in the seamless integration of new technologies, ensuring minimal disruption to your operations.",
    },
    {
      title: "On Going Support",
      description:
        "Continuous monitoring and support to ensure your technology remains up-to-date and effective.",
    },
  ];

  return (
    <div className={styles.OurCoreValues}>
      <Title title="Our Approach" color="#8FACE0" />
      <div className={styles.OurApproachContent}>
        <SubTitle
          title="Our approach is comprehensive and client-focused, ensuring that every step is aligned with your business goals and technological needs."
          color="#A8ACAF"
        />
      </div>
      <div className={styles.CoreValuesList}>
        <div className={styles.CoreValues}>
          {coreValues.map((value, index) => (
            <CoreValueCard
              key={index}
              title={value.title}
              description={value.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default OurApproach;

import styles from "./styles.module.css";

function SubTitle({ title = "SubTitle", color }) {
  return (
    <div className={styles.SubTitle} style={{ color: color }}>
      <h3>{title}</h3>
    </div>
  );
}

export default SubTitle;

import { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook for navigation
import styles from "./styles.module.css";

import logo from "../../assets/logo/logoPrimaryWhite.svg";
import NavigationLink from "../../ui/NavLink/NavLink";
import Button from "../../ui/Button/Button";
import MenuIcon from "../../assets/icons/MenuIcon.svg";
import CloseIcon from "../../assets/icons/CloseIcon.svg";

function NavBar() {
  const [hoveredLink, setHoveredLink] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false); // State to control drawer
  const navigate = useNavigate(); // Initialize the navigation hook

  const handleMouseEnter = (link) => {
    setHoveredLink(link);
  };

  const handleMouseLeave = () => {
    setHoveredLink(null);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLinkClick = (path) => {
    setDrawerOpen(false); // Close the drawer
    navigate(path); // Navigate to the clicked path
  };

  return (
    <nav className={styles.NavBar}>
      <NavigationLink to="/" icon={false}>
        <div className={styles.Logo}>
          <img src={logo} alt="Logo" />
        </div>
      </NavigationLink>
      <div className={styles.NavLinks}>
        <div
          className={styles.NavItem}
          onMouseEnter={() => handleMouseEnter("who-we-are")}
          onMouseLeave={handleMouseLeave}
        >
          <NavigationLink to="/who-we-are" text="Who We Are" />
          {hoveredLink === "who-we-are" && (
            <div className={styles.Dropdown}>
              <NavigationLink to="/who-we-are" text="About Us" icon={false} />
              <NavigationLink to="/who-we-are/faq" text="FAQ" icon={false} />
            </div>
          )}
        </div>

        <div
          className={styles.NavItem}
          onMouseEnter={() => handleMouseEnter("solutions-we-provide")}
          onMouseLeave={handleMouseLeave}
        >
          <NavigationLink
            to="/solutions-we-provide"
            text="Solutions We Provide"
          />
          {hoveredLink === "solutions-we-provide" && (
            <div className={styles.Dropdown}>
              <NavigationLink
                to="/solutions-we-provide/technology-consultation"
                text="Technology Consultation"
                icon={false}
              />
              <NavigationLink
                to="/solutions-we-provide/fintech-consultation"
                text="Fintech Consultation"
                icon={false}
              />
              <NavigationLink
                to="/solutions-we-provide/"
                text="Compliance Consultation"
                icon={false}
              />
              <NavigationLink
                to="/solutions-we-provide/revenue"
                text="Revenue"
                icon={false}
              />
            </div>
          )}
        </div>

        <div
          className={styles.NavItem}
          onMouseEnter={() => handleMouseEnter("resources")}
          onMouseLeave={handleMouseLeave}
        >
          <NavigationLink to="/resources" text="Resources" />
          {hoveredLink === "resources" && (
            <div className={styles.Dropdown}>
              <NavigationLink to="/resources" text="Blogs" icon={false} />
              <NavigationLink
                to="/resources/industry-news"
                text="Industry News"
                icon={false}
              />
              <NavigationLink
                to="/resources/privacy-policy"
                text="Privacy Policy"
                icon={false}
              />
              <NavigationLink
                to="/resources/terms-of-use"
                text="Terms of Use"
                icon={false}
              />
            </div>
          )}
        </div>
        <NavigationLink to="/career" text="Career" icon={false} />
      </div>
      <div className={styles.Contact}>
        <NavigationLink to="/contact">
          <Button text="Contact Us" />
        </NavigationLink>
      </div>
      <div className={styles.MenuIcon} onClick={toggleDrawer}>
        <img src={drawerOpen ? CloseIcon : MenuIcon} alt="Menu" />
      </div>
      <div
        className={`${styles.Drawer} ${drawerOpen ? styles.Open : ""}`}
        onClick={toggleDrawer}
      >
        <div
          className={styles.DrawerContent}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.DrawerHeader}>
            <NavigationLink to="/" icon={false}>
              <div className={styles.Logo}>
                <img src={logo} alt="Logo" />
              </div>
            </NavigationLink>
            <div className={styles.CloseIcon} onClick={toggleDrawer}>
              <img src={CloseIcon} alt="Close" />
            </div>
          </div>
          <div onClick={() => handleLinkClick("/")}>
            <NavigationLink to="/" text="Home" icon={false} />
          </div>
          <div onClick={() => handleLinkClick("/who-we-are")}>
            <NavigationLink to="/who-we-are" text="Who We Are" icon={false} />
          </div>
          <div onClick={() => handleLinkClick("/solutions-we-provide")}>
            <NavigationLink
              to="/solutions-we-provide"
              text="Solutions We Provide"
              icon={false}
            />
          </div>
          <div onClick={() => handleLinkClick("/resources")}>
            <NavigationLink to="/resources" text="Resources" icon={false} />
          </div>
          <div onClick={() => handleLinkClick("/career")}>
            <NavigationLink to="/career" text="Career" icon={false} />
          </div>
          <div
            style={{ position: "absolute", bottom: "100px" }}
            onClick={() => handleLinkClick("/contact")}
          >
            <NavigationLink to="/contact">
              <Button text="Contact Us" />
            </NavigationLink>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;

import styles from "./styles.module.css";
import { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import UniversalHeader from "../../components/UniversalHeader/UniversalHeader";
import SubNavLink from "../../ui/SubNavLink/SubNav";
import AboutUs from "./AboutUs/AboutUs";
// import OurTeam from "./OurTeam/OurTeam";
// import Testimonials from "./Testimonials/Testimonials";
import FAQ from "./FAQ/FAQ";

import withVisibility from "../../hoc/withVisibility/withVisibility";

function Navigation() {
  return (
    <div className={styles.Navigation}>
      <SubNavLink title="About Us" to="" />
      {/* <SubNavLink title="Team Members" to="our-team" /> */}
      {/* <SubNavLink title="Testimonials" to="testimonials" /> */}
      <SubNavLink title="FAQs" to="faq" />
    </div>
  );
}

const VisibleUniversalHeader = withVisibility(UniversalHeader);
const VisibleNavigation = withVisibility(Navigation);

function WhoWeAre() {
  const [title, setTitle] = useState("About Us");
  const [subtitle, setSubtitle] = useState(
    "Learn more about our history, mission, vision, and values that drive our success."
  );

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/who-we-are/faq") {
      setTitle("FREQUENTLY ASKED QUESTIONS (FAQs)");
      setSubtitle(
        "Frequently asked questions about our services, products, and company."
      );
    } else {
      setTitle("About Us");
      setSubtitle(
        "Learn more about our history, mission, vision, and values that drive our success."
      );
    }
  }, [location.pathname]);

  return (
    <div className={styles.WhoWeAre}>
      <VisibleUniversalHeader title={title} subtitle={subtitle} />
      <VisibleNavigation />
      <div className={styles.Content}>
        <Routes>
          <Route path="" element={<AboutUs />} />
          {/* <Route path="our-team" element={<OurTeam />} /> */}
          {/* <Route path="testimonials" element={<Testimonials />} /> */}
          <Route path="faq" element={<FAQ />} />
        </Routes>
      </div>
    </div>
  );
}

export default WhoWeAre;

import styles from "./styles.module.css";

import TitleAlternate from "../../ui/TitleAlternate/TitleAlternate";
import SubTitleAlternate from "../../ui/SubtitleAlternate/SubtitleAlternate";

import Headingillustration from "../../assets/illustration/Headingillustration.svg";

function UniversalHeader({ title, subtitle }) {
  return (
    <div className={styles.UniversalHeader}>
      <div className={styles.UniversalHeaderContent}>
        <TitleAlternate title={title} color="#333" />
        <SubTitleAlternate title={subtitle} color="#333" />
      </div>
      <div className={styles.UniversalHeaderIllustration}>
        <img src={Headingillustration} alt="Headingillustration" />
      </div>
    </div>
  );
}

export default UniversalHeader;

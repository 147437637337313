import React, { useState, useEffect, useRef } from "react";

import styles from "./styles.module.css";
import Title from "../../../../ui/Title/Title";
import Heading from "../../../../ui/Heading/Heading";
import InsightCard from "../../../../components/InsightCard/InsightCard";
import Button from "../../../../ui/Button/Button";
import NavigationLink from "../../../../ui/NavLink/NavLink";

function Insight() {
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRef = useRef(null);
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const articles = localStorage.getItem("articles");

    if (articles) {
      setArticles(JSON.parse(articles));
      // console.log(articles);
    } else {
      fetch(
        "https://api.mediastack.com/v1/news?access_key=fd11a72803e1351c1457cd3bbe3f0035&languages=en&categories=business,technology,science,-sports&limit=30&offset=30"
      )
        .then((response) => response.json())
        .then((data) => {
          setArticles(data);
          localStorage.setItem("articles", JSON.stringify(data));
        });
    }
  }, []);
  // console.log(articles.data)
  const cards = [
    {
      title: "How to improve your customer experience",
      description:
        "Learn how to improve your customer experience with these tips.",
      date: "March 12, 2021",
      image: "https://via.placeholder.com/150",
      tag: "Blog",
    },
    {
      title: "How to improve your customer experience",
      description:
        "Learn how to improve your customer experience with these tips.",
      date: "March 12, 2023",
      image: "https://via.placeholder.com/150",
      tag: "Case Study",
    },
    {
      title: "How to improve your customer experience",
      description:
        "Learn how to improve your customer experience with these tips.",
      date: "March 12, 2021",
      image: "https://via.placeholder.com/150",
      tag: "Blog",
    },
    {
      title: "How to improve your customer experience",
      description:
        "Learn how to improve your customer experience with these tips.",
      date: "March 12, 2023",
      image: "https://via.placeholder.com/150",
      tag: "Case Study",
    },
    {
      title: "How to improve your customer experience",
      description:
        "Learn how to improve your customer experience with these tips.",
      date: "March 12, 2021",
      image: "https://via.placeholder.com/150",
      tag: "Blog",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % cards.length;
        const cardHeight = contentRef.current.firstChild.clientHeight;

        contentRef.current.scrollTo({
          top: newIndex * cardHeight,
          behavior: "smooth",
        });

        return newIndex;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, [cards.length]);

  const handleScroll = () => {
    const cardHeight = contentRef.current.firstChild.clientHeight;
    const scrollTop = contentRef.current.scrollTop;
    const index = Math.round(scrollTop / cardHeight);
    setActiveIndex(index);
  };

  const handleIndicatorClick = (index) => {
    const cardHeight = contentRef.current.firstChild.clientHeight;

    setActiveIndex(index);
    contentRef.current.scrollTo({
      top: index * cardHeight,
      behavior: "smooth",
    });
  };

  return (
    <div className={styles.Insight}>
      <div className={styles.Header}>
        <Title title="Insight" color="#8FACE0" />
        <Heading
          title="Stay informed with expert articles on industry trends, best practices, and innovative solutions."
          color="#A8ACAF"
        />
        <NavigationLink to="/resources/industry-news">
          <Button text="View More" />
        </NavigationLink>
      </div>
      <div className={styles.Content} ref={contentRef} onScroll={handleScroll}>
        {articles && articles.data ? (
          articles.data
            .slice(0, 5)
            .map((card, index) => (
              <InsightCard
                key={index}
                title={card.title}
                description={card.description}
                date={card.date}
                image={card.image}
                tag={card.source}
                url={card.url}
              />
            ))
        ) : (
          <p>No articles available.</p>
        )}
      </div>
      <div className={styles.ScrollIndicator}>
        {articles && articles.data
          ? articles.data
              .slice(0, 5)
              .map((_, index) => (
                <div
                  key={index}
                  className={`${styles.IndicatorDot} ${
                    index === activeIndex ? styles.active : ""
                  }`}
                  onClick={() => handleIndicatorClick(index)}
                />
              ))
          : null}
      </div>
    </div>
  );
}

export default Insight;

import styles from "./styles.module.css";

import { useEffect } from "react";

import withVisibility from "../../../hoc/withVisibility/withVisibility";

import FAQuestions from "./FAQuestions/FAQuestions";
import ContactSection from "./ContactSection/ContactSection";

const VisibleFAQuestions = withVisibility(FAQuestions);
const VisibleContactSection = withVisibility(ContactSection);

function FAQ() {
   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);
  return (
    <div className={styles.FAQ}>
      <VisibleFAQuestions />
      <VisibleContactSection />
    </div>
  );
}

export default FAQ;

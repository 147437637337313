import styles from "./styles.module.css";

import Title from "../../../../../ui/Title/Title";
import SubTitle from "../../../../../ui/SubTitle/SubTitle";
import CoreValueCard from "../../../../../components/CoreValueCard/CoreValueCard";

function OurApproach() {
  const coreValues = [
    {
      title: "Market Analysis",

      description:
        "We begin with a thorough analysis of the fintech market to identify opportunities and challenges specific to your business.",
    },
    {
      title: "Strategic Planning",
      description:
        "Our team develops a customised strategy that aligns with your business goals and regulatory requirements.",
    },
    {
      title: "Implementation",
      description:
        "We assist in the deployment of fintech solutions, ensuring seamless integration with your existing systems.",
    },
    {
      title: "Regulatory Compliance",
      description:
        "Continuous support to ensure your fintech operations remain compliant with evolving regulations.",
    },
  ];

  return (
    <div className={styles.OurCoreValues}>
      <Title title="Our Approach" color="#8FACE0" />
      <div className={styles.OurApproachContent}>
        <SubTitle
          title="Our approach to fintech consultation is centred around innovation and compliance, ensuring that your business not only meets current demands but also anticipates future trends."
          color="#A8ACAF"
        />
      </div>
      <div className={styles.CoreValuesList}>
        <div className={styles.CoreValues}>
          {coreValues.map((value, index) => (
            <CoreValueCard
              key={index}
              title={value.title}
              description={value.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default OurApproach;

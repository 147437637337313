import styles from "./styles.module.css";

import Title from "../../../../../ui/Title/Title";
import Title2 from "../../../../../ui/Title2/Title2";
import Paragraph from "../../../../../ui/Paragrah/Paragraph";

// import placeholder from "../../../../assets/images/Placeholder.png";

import technologyConsultation from "../../../../../assets/images/technologyConsultation.png";

function AboutSolution({ backgroundColor }) {
  return (
    <div className={styles.WeAre} style={{ backgroundColor: backgroundColor }}>
      <div className={styles.WeAreContent}>
        <Title title="Technology Consultation" color="#8FACE0" />
        <Paragraph
          text="Our Technology Consultation service is designed to help businesses leverage the latest technologies to enhance efficiency, security, and innovation. Whether you're looking to upgrade your IT infrastructure, adopt cloud solutions, or implement new software, our experts are here to guide you every step of the way."
          color="#A8ACAF"
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Title2 title="Benefits" color="#8FACE0" />
          <ul
            style={{
              color: "#A8ACAF",
              textAlign: "left",
              lineHeight: "2.0",
              fontSize: "16px",
            }}
          >
            <li>
              <span style={{ color: "#8FACE0" }}>Scalable Solutions:</span>{" "}
              Tailored to fit your business needs and growth.
            </li>
            <li>
              <span style={{ color: "#8FACE0" }}>Expert Guidance:</span> Work
              with industry professionals who understand the latest trends.
            </li>
            <li>
              <span style={{ color: "#8FACE0" }}>Cost Efficiency:</span>{" "}
              Optimize your technology investments.
            </li>
            <li>
              <span style={{ color: "#8FACE0" }}>Enhanced Security:</span>{" "}
              Protect your business with advanced cybersecurity solutions.
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.WeAreImage}>
        <img src={technologyConsultation} alt="Placeholder" />
      </div>
    </div>
  );
}

export default AboutSolution;

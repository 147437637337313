import styles from "./styles.module.css";

import { useEffect } from "react";

import PolicyContainer from "./components/PolicyContainer/PolicyContainer";

import withVisibility from "../../../hoc/withVisibility/withVisibility";

const VisiblePolicyContainer = withVisibility(PolicyContainer);

function PrivacyPolicy() {
   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);
  return (
    <div className={styles.PrivacyPolicy}>
      <VisiblePolicyContainer />
    </div>
  );
}

export default PrivacyPolicy;

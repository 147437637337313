import styles from "./styles.module.css";

function ButtonAlternate({
  text = "Button",
  onClick,
  padding,
  backgroundColor,
  color,
}) {
  return (
    <button
      className={styles.Button}
      onClick={onClick}
      style={{
        padding: padding,
        backgroundColor: backgroundColor,
        color: color,
      }}
    >
      {text}
    </button>
  );
}

export default ButtonAlternate;

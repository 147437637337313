import styles from "./styles.module.css";


// import XIcon from "../../assets/icons/XIcon.svg";
// import InstagramIcon from "../../assets/icons/InstagramIcon.svg";
import LinkedInIcon from "../../assets/icons/LinkedInIcon.svg";
// import YouTubeIcon from "../../assets/icons/YouTubeIcon.svg";

function ContactCardAlternative({ title, phone, email }) {
  return (
    <div className={styles.ContactCard}>
      <h3>{title}</h3>
      <div className={styles.ContactText}>
        <p className={styles.TextTitle}>Call Us On:</p>
        <p className={styles.TextContent}>{phone}</p>
      </div>
      <div className={styles.ContactText}>
        <p className={styles.TextTitle}>Email Us On:</p>
        <p className={styles.TextContent}>{email}</p>
      </div>
      <div className={styles.SocialIcons}>
        {/* <a href="https://www.instagram.com/">
          <img src={XIcon} alt="X Icon" />
        </a>
        <a href="https://www.linkedin.com/">
          <img src={InstagramIcon} alt="Instagram Icon" />
        </a> */}
        <a
          href="https://www.linkedin.com/in/voguewebglobal-7a71a6327"
          target="blank"
        >
          <img src={LinkedInIcon} alt="LinkedIn Icon" />
        </a>
        {/* <a href="https://www.youtube.com/">
          <img src={YouTubeIcon} alt="YouTube Icon" />
        </a> */}
      </div>
    </div>
  );
}

export default ContactCardAlternative;

import styles from "./styles.module.css";
import { NavLink } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa6";

function NavigationLink({ to = "/", text = "Home", children, icon, onClick }) {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        isActive
          ? `${styles.NavigationLink} ${styles.active}`
          : styles.NavigationLink
      }
      onClick={onClick} // Pass the onClick handler to manage the drawer state
    >
      {children ? children : text}
      <div className={styles.Icon}>
        {children || icon === false ? null : <FaAngleDown />}
      </div>
    </NavLink>
  );
}

export default NavigationLink;

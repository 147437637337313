import styles from "./styles.module.css";

import Title from "../../../../ui/Title/Title";
import Paragraph from "../../../../ui/Paragrah/Paragraph";

// import placeholder from "../../../../assets/images/Placeholder.png";
import whoWe from "../../../../assets/images/whoWe.png";

function WeAre({ backgroundColor }) {
  return (
    <div className={styles.WeAre} style={{ backgroundColor: backgroundColor }}>
      <div className={styles.WeAreContent}>
        <Title title="Who We Are" color="#8FACE0" />
        <Paragraph
          text="We are a collective of highly experienced professionals specialising in
Fintech, Compliance and Tax, Revenue Growth, and Technological
Architecture. Our expertise lies in helping governments and businesses
navigate the complexities of the digital economy. By assessing
infractions by digital platforms, enhancing regulatory environments, and
ensuring the collection of dues, we empower countries to safeguard their
economic interests. We also assist businesses in enhancing their market
knowledge, assessing regulatory risks, and developing strategies that
align with current compliance requirements and future forecasts. 
"
          color="#A8ACAF"
        />
        <Paragraph
          text="Our team is dedicated
to bridging the gap between businesses and regulators, particularly
across Africa, to foster sustainable growth and innovation.
"
          color="#A8ACAF"
        />
      </div>
      <div className={styles.WeAreImage}>
        <img src={whoWe} alt="Placeholder" />
      </div>
    </div>
  );
}

export default WeAre;

import styles from "./styles.module.css";

import { useEffect } from "react";

import Header from "./components/Header/Header";
import Solutions from "./components/Solutions/Solutions";
import Impact from "./components/Impact/Impact";
import Insight from "./components/Insight/Insight";
import ContactBarner from "../../components/ContactBarner/ContactBarner";
import WeAre from "../WhoWeAre/AboutUs/WeAre/WeAre";
import withVisibility from "../../hoc/withVisibility/withVisibility";

const VisibleHeader = withVisibility(Header);
const VisibleSolutions = withVisibility(Solutions);
const VisibleImpact = withVisibility(Impact);
const VisibleInsight = withVisibility(Insight);
const VisibleContactBarner = withVisibility(ContactBarner);
const VisibleWeAre = withVisibility(WeAre);

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.Home}>
      <VisibleHeader />
      <VisibleWeAre />
      <VisibleSolutions />
      <VisibleImpact />
      <VisibleInsight />
      <VisibleContactBarner title="Ready to transform your Business?" />
    </div>
  );
}

export default Home;

import React, { useState } from "react";
import styles from "./styles.module.css";
import Paragraph from "../../../../ui/Paragrah/Paragraph";

function FAQuestions() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "How Can I Get A Quote For Your Services?",
      answer: `You can request a quote by emailing to enquiries@vwebconsultancy.com or calling us directly.`,
    },
    {
      question: "Where Are Your Offices Located?",
      answer: `We have offices in the FCT, and London. See our office locations section for more details.`,
    },
    {
      question: "What's Your Business Working Hours?",
      answer: `Our business hours are from 8:00 AM to 5:00 PM, Monday to Friday.`,
    },
    {
      question: "What Services Do You Offer?",
      answer: `We offer a wide range of services including technology consultation, fintech consultation, compliance consultation, and revenue consultation.`,
    },
    {
      question: "What Payment Methods Do You Accept?",
      answer: `We accept all major credit cards, PayPal, and bank transfers.`,
    },
    {
      question: "Do You Offer Custom Solutions?",
      answer: `Yes, we offer custom solutions tailored to your business needs.`,
    },
    {
      question: "How Can I Get In Touch With Your Support Team?",
      answer: `You can get in touch with our support team by calling us at [Phone Number] or emailing us at [Email Address
        ].`,
    },
  ];

  return (
    <div className={styles.FAQuestions} id="faq">
      <div className={styles.Heading}>
        <h1>Frequently Asked Questions</h1>
        <div className={styles.Underline}></div>
      </div>
      <div className={styles.ContainerLeft}>
        <Paragraph
          text="FREQUENTLY ASKED QUESTIONS (FAQs)."
          color="#A8ACAF"
          fontSize={"16px"}
        />
      </div>
      <div className={styles.ContainerRight}>
        <div className={styles.FAQAccordion}>
          {faqs.map((faq, index) => (
            <React.Fragment key={index}>
              <button
                className={`${styles.FAQAccordionButton} ${
                  activeIndex === index ? styles.active : ""
                }`}
                onClick={() => toggleAccordion(index)}
              >
                <h3>Q. {faq.question}</h3>
              </button>
              <div
                className={`${styles.FAQAccordionContent} ${
                  activeIndex === index ? styles.active : ""
                }`}
              >
                <p>{faq.answer}</p>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FAQuestions;

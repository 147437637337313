import styles from "./styles.module.css";

import { useEffect } from "react";

import TermsContainer from "./components/TermsContainer/TermsContainer";

import withVisibility from "../../../hoc/withVisibility/withVisibility";

const VisibleTermsContainer = withVisibility(TermsContainer);

function TermsOfUse() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.TermsOfUse}>
      <VisibleTermsContainer />
    </div>
  );
}

export default TermsOfUse;

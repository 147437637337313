import styles from "./styles.module.css";

import ContactCard from "../../../../components/ContactCard/ContactCard";
import ContactCardAlternative from "../../../../components/ContactCardAlternative/ContactCardAlternative";

const ContactSection = () => {
  return (
    <div className={styles.ContactSection}>
      <div className={styles.Title}>
        <h2>Didn't find what you're looking for? </h2>
        <p>
          Our team is just an email away, ready to assist with any questions you
          may
          <br />
          have.
        </p>
      </div>
      <div className={styles.ContactLists}>
        <ContactCard
          title="Office Location"
          office="International Office"
          address="Level39, One Canada Square, Canary Wharf, London, E14 5AB"
          phone="+44 20 8050 3459"
          email="london@vwebconsultancy.com"
        />
        <ContactCard
          title="Office Location"
          office="Nigeria Office"
          address="918 Abubakar KoKo Avenue, FCT, Abuja, 900108"
          phone="+234 80 2795 6216"
          email="fct@vwebconsultancy.com"
        />
        <ContactCardAlternative
          title="Direct Contact Information"
          phone="+234 80 2795 6216"
          email="enquiries@vwebconsultancy.com"
        />
      </div>
    </div>
  );
};

export default ContactSection;
